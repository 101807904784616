
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { computed, defineComponent, PropType, ref } from 'vue'
import { getLocaleLanguage } from '@/locales'

export default defineComponent({
  name: 'MFooter',
  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: '#000000'
    },
    textColor: {
      type: String as PropType<string>,
      default: '#ffffff'
    }
  },
  setup() {
    const contentWeight = ref(window.innerWidth)
    const isShowMobile = ref(false)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 825) {
          isShowMobile.value = true
          return
        }
        isShowMobile.value = false
      },
      200,
      { immediate: true }
    )

    const language = computed(() => {
      return getLocaleLanguage()
    })

    const isCompany = computed(() => {
      return process.env.VUE_APP_USE === 'company'
    })

    return {
      isShowMobile,
      contentWeight,
      language,
      isCompany
    }
  }
})
