import { SelectTypeItem } from '@/types/common'
import i18n from '@/locales/index'
const { t } = i18n.global

export const languageList: SelectTypeItem[] = [
  {
    label: t('language.zh'),
    value: 'zh'
  },
  {
    label: t('language.en'),
    value: 'en'
  },
  {
    label: t('language.ar'),
    value: 'ar'
  },
  {
    label: t('language.in'),
    value: 'in'
  },
  {
    label: t('language.ms'),
    value: 'ms'
  }
]
