const env = process.env.VUE_APP_ENV || 'development'
const versionName = process.env.VUE_APP_NAME || ''

console.log(`------- 当前环境: ${JSON.stringify(process.env)} ${JSON.stringify(process.env.VUE_APP_NAME)}-------`)
interface IConfig {
  baseURL: string
  webLogUrl: string
  gaMeasurementId: string
  loginURL: string
  homeLoginSecret: string
  aliOssURL: string
  secretKey: string
  transponderURL: string
  meWebsiteURL: string
  payHttpSGate: string
}

const defaultConfig: IConfig = {
  baseURL: 'http://dev-meta-api.evkland.cn',
  webLogUrl: 'https://s4.cnzz.com/z_stat.php?id=1279961443&web_id=1279961443',
  gaMeasurementId: 'G-J31RCBXFS7',
  loginURL: 'http://dev-meta-api.evkland.cn',
  homeLoginSecret: 'fed33392d3a48aa149a87a38b875ba4a',
  aliOssURL: 'http://dev-meta-api.evkland.cn',
  secretKey: 'UObXNuJNUW7qawSBCccXZTCngVyNHgXN',
  transponderURL: 'https://transponder.weelifeapp.com',
  meWebsiteURL: 'http://dev-meta.evkland.cn',
  payHttpSGate: 'https://sandbox.sgate.sa'
}

const dev: IConfig = {
  ...defaultConfig
}

const test: IConfig = {
  ...defaultConfig
}

const beta: IConfig = {
  ...defaultConfig
}

const stable: IConfig = {
  baseURL: versionName === 'me' ? 'https://stable-me-meta-api.evkland.cn'
    : 'https://stable-meta-api.evkland.cn',
  webLogUrl: 'https://s4.cnzz.com/z_stat.php?id=1279961443&web_id=1279961443',
  gaMeasurementId: 'G-J31RCBXFS7',
  loginURL: versionName === 'me' ? 'https://stable-me-meta-api.evkland.cn' : 'https://stable-meta-api.evkland.cn',
  homeLoginSecret: 'fed33392d3a48aa149a87a38b875ba4a',
  aliOssURL: versionName === 'me' ? 'https://stable-me-meta-api.evkland.cn' : 'https://stable-meta-api.evkland.cn',
  secretKey: 'UObXNuJNUW7qawSBCccXZTCngVyNHgXN',
  transponderURL: 'https://transponder.weelifeapp.com',
  meWebsiteURL: 'http://stable-me-meta.evkland.cn',
  payHttpSGate: 'https://gateway.sgate.sa'
}

const prod: IConfig = {
  baseURL: versionName === 'me' ? 'https://api-me.weelifeapp.com' : 'https://api.weelifeapp.com',
  webLogUrl: 'https://v1.cnzz.com/z_stat.php?id=1280075894&web_id=1280075894',
  gaMeasurementId: 'G-K6FEW7N15X',
  loginURL: versionName === 'me' ? 'https://api-me.weelifeapp.com' : 'https://api.weelifeapp.com',
  homeLoginSecret: 'fed33392d3a48aa149a87a38b875ba4a',
  aliOssURL: versionName === 'me' ? 'https://api-me.weelifeapp.com' : 'https://api.weelifeapp.com',
  secretKey: 's3Rdvth10ZuWn5sa3b5IxH8BBLL4OrIp',
  transponderURL: 'https://transponder.weelifeapp.com',
  meWebsiteURL: 'https://me.weelifeapp.com',
  payHttpSGate: 'https://gateway.sgate.sa'
}

let config: IConfig = dev

switch (env) {
  case 'development':
    config = dev
    break
  case 'test':
    config = test
    break
  case 'stable':
    config = stable
    break
  case 'beta':
    config = beta
    break
  case 'production':
    config = prod
    break
}

export default config
