/* eslint-disable @typescript-eslint/no-explicit-any */
import { stringify } from 'qs'
import CryptoJS from 'crypto-js'

/**
 * 过滤赋值为空的字段
 *
 * @param data data
 */
export function filterDataEmpty(data: any): void {
  for (const key in data) {
    if (data[key] === '') {
      delete data[key]
    }
  }
}

/**
 * 参数签名(object)
 *
 * @param data data
 * @param secretKey secretKey
 */
export function getSignedData(data: any, secretKey: string): string {
  const keys: any = Object.keys(data).sort()
  let sign = ''
  const sortData: any = {}
  keys.forEach((key: string) => {
    let value = data[key]
    if (value === 'object') {
      value = JSON.stringify(value)
    }
    if (Array.isArray(value)) {
      value = JSON.stringify(value)
    }
    if (!(value === undefined || value === null || value === '')) {
      sortData[key] = value
    }
  })
  let source = stringify(sortData, {
    encode: false
  })
  source = source + '&' + secretKey
  sign = CryptoJS.MD5(source).toString().toLowerCase()
  // console.log(`签名参数: ${source}, 签名结果:${sign}`);
  return sign
}

/**
 * 参数签名(string)
 *
 * @param  data data
 * @param secretKey secretKey
 */
export function getSignedDataStr(data: any, secretKey: string): string {
  let source = stringify(data)
  const signSource = source + secretKey
  source += 'sign=' + CryptoJS.MD5(signSource).toString().toLowerCase()
  // console.log(`参数：${source}`);
  return source
}

/**
 * 获取时间戳（秒）
 */
export function getTimestamp(): number {
  return Date.now()
}

/**
 * 获取随机数(0-10000]
 */
export function getRandom(): number {
  return (Math.random() * 10000) << 0
}

/**
 * 获取指定位数随机数
 *
 * @param len 随机数长度
 */
export function randomNum(len: number): string {
  let salt = ''
  for (let i = 0; i < len; i++) {
    let tmp = String(Math.floor(Math.random() * 10))
    if (!tmp) {
      tmp = '2'
    }
    salt += tmp
  }
  return salt
}
