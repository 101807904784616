import errorCode, { errorCodeType } from '@/api/types/errorCode'
import { getLocale } from '@/locales'

export const errorCodeLocales = (code: string) => {
  const language = getLocale()
  const message = errorCode.find(item => item.code === code) as errorCodeType
  if (language.indexOf('zh') > -1) {
    return message.message
  } else if (language.includes('ar')) {
    return message.arMessage
  } else if (language.includes('id') || language.includes('in')) {
    return message.idMessage
  } else if (language.includes('ms')) {
    return message.msMessage
  }
  return message.enMessage
}
