<template>
  <MHeaderV2 v-if="isShowHeader" :background="headerBackground" :headerNameLogo="headerNameLogo"
    :headerTabList="headerTabList" :isShowTabs="isShowTabs" :isShowHamburger="isShowHamburger" :tabActive="tabActive"
    :tabColor="tabColor" :titleInactiveColor="titleInactiveColor" :titleActiveColor="titleActiveColor"
    :hamburgerColor="hamburgerColor" :headerBorderBottom="headerBorderBottom">
  </MHeaderV2>
  <div class="main" id="main">
    <router-view />
  </div>
</template>

<script lang="ts">
import { MHeaderV2 } from '@/views/components/index'
import { defineComponent, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { IHeaderTabItem } from './views/components/headerV2.vue'
import { useWindowSizeFn } from './hooks/useWindowSizeFn'
import store from './store'
import { useScrollTopFn } from './hooks/useScrollTopFn'
import { findIndex } from 'lodash'
import { useScrollTo } from './hooks/useScrollTo'
import header from './header'

interface IAppState {
  isShowHeader: boolean
  isShowTabs: boolean
  isShowHamburger: boolean
  headerNameLogo: string
  headerBackground: string
  tabActive: string | number
  tabColor: string
  titleInactiveColor: string
  titleActiveColor: string
  hamburgerColor: string
  headerBorderBottom: string
}
export default defineComponent({
  name: 'App',
  components: {
    MHeaderV2
  },
  setup() {
    const state = reactive<IAppState>({
      isShowHeader: false,
      isShowTabs: true,
      isShowHamburger: false,
      headerNameLogo: 'logo-black.png',
      headerBackground: 'none',
      tabActive: '',
      tabColor: 'rgba(47, 47, 47, 1)',
      titleInactiveColor: 'rgba(255, 255, 255, 0.4)',
      titleActiveColor: '#FFFFFF',
      hamburgerColor: '#000000',
      headerBorderBottom: ''
    })
    const headerTabList = ref<IHeaderTabItem[]>([...header])

    const setHeaderStyle = (val: string) => {
      if (val === '/') {
        state.headerNameLogo = store.state.app.swiperIndex !== 0 ? 'logo-black.png' : 'logo-white.png'
        state.tabColor = store.state.app.swiperIndex !== 0 ? 'rgba(47, 47, 47, 1)' : '#FFFFFF'
        state.titleInactiveColor = store.state.app.swiperIndex !== 0 ? 'rgba(47, 47, 47, 0.5)' : 'rgba(255, 255, 255, 0.4)'
        state.titleActiveColor = store.state.app.swiperIndex !== 0 ? 'rgba(47, 47, 47, 1)' : '#FFFFFF'
        state.headerBorderBottom = store.state.app.swiperIndex !== 0 ? '1px solid rgba(47, 47, 47, 0.2)' : '1px solid rgba(255, 255, 255, 0.2)'
        if (store.state.app.swiperIndex === 5) {
          state.headerNameLogo = 'logo-white.png'
          state.headerBackground = 'none'
          state.tabColor = '#FFFFFF'
          state.titleInactiveColor = 'rgba(255, 255, 255, 0.8)'
          state.titleActiveColor = '#FFFFFF'
          if (window.innerWidth <= 825) {
            state.headerNameLogo = 'logo-black.png'
          }
        }
        if (window.innerWidth <= 825) {
          state.isShowTabs = false
          state.isShowHamburger = true
          state.headerBackground = 'none'
          return
        }
        state.isShowTabs = true
        state.isShowHamburger = false
      } else if (val === '/about') {
        state.headerNameLogo = 'logo-white.png'
        state.headerBackground = 'none'
        state.tabColor = '#FFFFFF'
        state.titleInactiveColor = 'rgba(255, 255, 255, 0.8)'
        state.titleActiveColor = '#FFFFFF'
        if (window.innerWidth <= 825) {
          state.headerNameLogo = 'logo-black.png'
          state.isShowTabs = false
          state.isShowHamburger = true
          return
        }
        state.isShowTabs = true
        state.isShowHamburger = false
        state.hamburgerColor = '#000000'
      } else if (val === '/priv' || val === '/userve') {
        state.headerBackground = 'rgba(209, 212, 249, 0.8)'
        state.titleInactiveColor = 'rgba(47, 47, 47, 0.5)'
        state.titleActiveColor = 'rgba(47, 47, 47, 1)'
        state.tabColor = 'rgba(47, 47, 47, 1)'
        if (window.innerWidth <= 825) {
          state.titleInactiveColor = 'rgba(255, 255, 255, 0.8)'
          state.headerNameLogo = 'logo-black.png'
          state.isShowTabs = false
          state.isShowHamburger = true
          state.hamburgerColor = '#000000'
          return
        }
        state.isShowTabs = true
        state.isShowHamburger = false
        state.headerNameLogo = 'logo-black.png'
        state.hamburgerColor = '#000000'
      } else if (val === '/recharge') {
        state.titleInactiveColor = 'rgba(47, 47, 47, 0.5)'
        state.titleActiveColor = 'rgba(47, 47, 47, 1)'
        state.tabColor = 'rgba(47, 47, 47, 1)'
        state.isShowTabs = true
        state.isShowHamburger = false
        state.headerNameLogo = 'logo-black.png'
        state.headerBackground = 'rgba(209, 212, 249, 0.2)'
        state.hamburgerColor = '#000000'
        if (window.innerWidth <= 825) {
          state.headerNameLogo = 'logo-black.png'
          state.isShowTabs = false
          state.isShowHamburger = true
          state.headerBackground = '#FFFFFF'
          state.headerBorderBottom = '1px solid rgba(47, 47, 47, 0.2)'
        }
      } else {
        state.titleInactiveColor = 'rgba(47, 47, 47, 0.5)'
        state.titleActiveColor = 'rgba(47, 47, 47, 1)'
        state.tabColor = 'rgba(47, 47, 47, 1)'
        state.hamburgerColor = '#000000'
        if (window.innerWidth <= 825) {
          state.isShowTabs = false
          state.isShowHamburger = true
          state.headerNameLogo = 'logo-black.png'
          return
        }
        state.isShowTabs = true
        state.isShowHamburger = false
        state.headerNameLogo = 'logo-black.png'
        state.headerBackground = 'rgba(209, 212, 249, 0.5)'
      }
    }

    // 页面滚动至头部
    const scrollToTop = () => {
      nextTick(() => {
        const appElement = document.getElementById('app')
        if (appElement) {
          const { start } = useScrollTo({
            el: appElement,
            to: 0
          })
          start()
        }
      })
    }

    const route = useRoute()
    const noAppShowHeaderRouters = ['/priv', '/userve', '/quick-guide', '/quick-start-guide', '/ktv-party', '/recharge']
    const noWebShowHeaderRouters = ['/quick-guide', '/quick-start-guide', '/ktv-party']
    watch([() => route.path, () => navigator.userAgent.toLowerCase()], ([pathVal, userAgent]) => {
      if (pathVal !== '/') {
        store.commit('app/setSwiperIndex', null)
        scrollToTop()
      } else {
        store.commit('app/setSwiperIndex', 0)
      }
      state.tabActive = route.name as string
      if (pathVal === '/login' || pathVal === '/upload') {
        state.tabActive = 'Music'
      }
      setHeaderStyle(pathVal)
      /**
      * 判断是否在app内打开
      */
      if (userAgent.indexOf('weelife') > -1 || userAgent.indexOf('weelife-unity') > -1) {
        state.isShowHeader = !noAppShowHeaderRouters.includes(pathVal)
      } else {
        state.isShowHeader = !noWebShowHeaderRouters.includes(pathVal)
      }
    }, { immediate: true })

    useWindowSizeFn(
      () => {
        setHeaderStyle(route.path)
        const musicTabIndex = findIndex(headerTabList.value, { name: 'Music' })
        if (musicTabIndex > -1) {
          if (window.innerWidth <= 825) {
            headerTabList.value[musicTabIndex].isShowMobile = true
            return
          }
          headerTabList.value[musicTabIndex].isShowMobile = false
        }
      },
      200,
      { immediate: true }
    )

    useScrollTopFn(
      (e: any) => {
        if (e.target) {
          const target = e.target as HTMLElement
          const scrollDistance = window.scrollY || document.documentElement.scrollTop || target.scrollTop
          if (route.path === '/about') {
            if (scrollDistance < 200) {
              state.headerBackground = 'none'
              state.tabColor = '#FFFFFF'
              state.titleInactiveColor = 'rgba(255, 255, 255, 0.4)'
              state.titleActiveColor = '#FFFFFF'
              if (window.innerWidth > 825) {
                state.headerNameLogo = 'logo-white.png'
                return
              }
              state.headerNameLogo = 'logo-black.png'
            } else {
              state.headerNameLogo = 'logo-black.png'
              state.headerBackground = 'rgba(209, 212, 249, 0.9)'
              state.tabColor = 'rgba(47, 47, 47, 1)'
              state.titleInactiveColor = 'rgba(47, 47, 47, 0.5)'
              state.titleActiveColor = 'rgba(47, 47, 47, 1)'
            }
          }
        }
      }, 0,
      { immediate: true }
    )

    watch(() => store.state.app.swiperIndex, (val) => {
      if (route.path === '/about') {
        state.headerNameLogo = 'logo-white.png'
        state.headerBackground = 'none'
        state.tabColor = '#FFFFFF'
        state.titleInactiveColor = 'rgba(255, 255, 255, 0.4)'
        state.titleActiveColor = '#FFFFFF'
        state.hamburgerColor = '#000000'
        if (window.innerWidth <= 825) {
          state.headerNameLogo = 'logo-black.png'
          state.headerBackground = 'none'
        }
        return
      } else if (route.path !== '/') {
        // state.headerBackground = 'rgba(209, 212, 249, 0.8)'
        return
      }
      state.headerNameLogo = val !== 0 ? 'logo-black.png' : 'logo-white.png'
      state.headerBackground = val !== 0 ? 'rgba(209, 212, 249, 0.2)' : 'none'
      state.tabColor = val !== 0 ? 'rgba(47, 47, 47, 1)' : '#FFFFFF'
      state.titleInactiveColor = val !== 0 ? 'rgba(47, 47, 47, 0.5)' : 'rgba(255, 255, 255, 0.4)'
      state.titleActiveColor = val !== 0 ? 'rgba(47, 47, 47, 1)' : '#FFFFFF'
      state.hamburgerColor = val !== 0 ? '#000000' : '#FFFFFF'
      if (window.innerWidth <= 825) {
        state.isShowTabs = false
        state.isShowHamburger = true
        state.headerBackground = 'none'
        state.headerBorderBottom = val !== 0 ? '1px solid rgba(47, 47, 47, 0.2)' : '1px solid rgba(255, 255, 255, 0.2)'
      }
      if (val === 5) {
        state.headerNameLogo = 'logo-white.png'
        state.headerBackground = 'none'
        state.titleInactiveColor = 'rgba(255, 255, 255, 0.8)'
        state.titleActiveColor = '#FFFFFF'
        state.tabColor = '#FFFFFF'
        if (window.innerWidth <= 725) {
          state.headerNameLogo = 'logo-black.png'
        }
      }
    }, { immediate: true }
    )

    return {
      headerTabList,
      ...toRefs(state)
    }
  }
})
</script>
<style lang='scss' scoped>
.main {
  overflow-x: hidden;
}
</style>
